
import pro1 from '../assets/project1.png';
import pro2 from '../assets/project2.png';
import pro3 from '../assets/project3.png';

const projectCardData = [
  {
    imgsrc : pro1,
    title : "Ecommerce Website Design",
    text: "This is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website.",
view:"https://www.digikala.com",
source: "www.github.com/Adele-1991"
  },
  {
    imgsrc : pro2,
    title : "Education Design",
    text: "This is a website for education system. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website.",
view:"https://www.darsman.com",
source: "www.github.com/Adele-1991"
  },
  {
    imgsrc : pro3,
    title : "Automative parts Website Design",
    text: "This is a website for automative parts shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website. It is a website for online shopping. It is a fully responsive website.",
view:"https://www.partineh.com",
source: "www.github.com/Adele-1991"
  },
]
export default projectCardData;